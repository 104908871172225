<template>
    <b-container>
        <iframe style="width: 100%; height: 840px;" :src="data.cv.url" />
    </b-container>
</template>
<script>
    export default {
    }
</script>
<style scoped>
</style>
