import Vue from 'vue'
import Router from 'vue-router'
// import IntroRoute from '@/components/routes/IntroRoute'
import ProjectsRoute from '@/components/routes/ProjectsRoute'
import ContactRoute from '@/components/routes/ContactRoute'
import CVRoute from '@/components/routes/CVRoute'
import vueConfig from '@/../vue.config'

Vue.use(Router)
const router = new Router({
    base: vueConfig.publicPath,
    routes: [
        // {
        //     path: '/',
        //     name: 'IntroRoute',
        //     alias: '*',
        //     component: IntroRoute,
        //     meta: {
        //         title: 'Intro'
        //     }
        // },
        {
            path: '/',
            name: 'ProjectsRoute',
            alias: '*',
            component: ProjectsRoute,
            meta: {
                title: ''
            }
        },
        {
            path: '/contact',
            name: 'ContactRoute',
            alias: '*',
            component: ContactRoute,
            meta: {
                title: '- Contact'
            }
        },
        {
            path: '/cv',
            name: 'CVRoute',
            alias: '*',
            component: CVRoute,
            meta: {
                title: '- CV'
            }
        }
    ]
})

router.beforeResolve((to, from, next) => {
    next()
})

export default router
