<template>
    <div>
        <b-navbar>
            <b-container>
                <b-navbar-brand href="">{{ data.headText }}</b-navbar-brand>
                <b-navbar-nav>
                    <b-nav-item :href="data.cv.url" target="_blank"><font-awesome-icon icon="file" /> CV</b-nav-item>
                    <b-nav-item @click="showModal"><font-awesome-icon icon="envelope" /> Contact</b-nav-item>
                </b-navbar-nav>
            </b-container>
        </b-navbar>
        <router-view class="mt-5" />
        <div class="mt-5 text-center" v-html="data.footer" />
        <b-modal
            ref="modal"
            size="lg"
            ok-only
            hide-footer
            button-size="sm"
            body-bg-variant="dark"
            header-bg-variant="dark"
            footer-bg-variant="dark"
            header-text-variant="light"
            footer-text-variant="light"
            :title="data.contact.title"
        >
            <div v-html="data.contact.body" />
        </b-modal>
    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
            }
        },
        watch: {
            $route: {
                handler() {
                    document.title = `John Moschos ${this.$route.meta.title}`
                },
                immediate: true
            }
        },
        methods: {
            showModal() {
                this.$refs.modal.show()
            }
        }
    }
</script>

<style>
    /* html, body {
        background: #252627!important;
        color: #fff!important;
    } */
    #app {
        width: 100%;
        margin: 0px;    /* ios only */
        padding: 0px;   /* ios only */
        height: 100%;
        /* overflow-y: hidden; */
    }

</style>
