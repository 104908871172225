// import axios from 'axios'
const mixin = {
    data() {
        return {
            data: {
                intro: {
                    about: {
                        title: '',
                        body: ''
                    },
                    projects: []
                },
                projects: [],
                contact: {
                    title: '',
                    body: '',
                    linkedIn: {
                        display: false,
                        id: '',
                        url: ''
                    }
                },
                cv: {
                    url: ''
                },
                footer: '',
                headText: '',
                theme: {
                    backgroundColor: ''
                }
            }
        }
    },
    created() {
        if (window.data) {
            this.data = JSON.parse(window.data)
        }
        // axios.get('../data.json').then(resp => {
        //     this.data = resp.data
        // })
    }
}

export default mixin
