import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faTh, faFile, faEnvelope, faExpand, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue'
import App from '@/App'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from '@/router'
import data from '@/mixins/data'

library.add(faUser, faTh, faFile, faEnvelope, faExpand, faTimes)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.mixin(data)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
