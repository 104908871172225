<template>
    <b-container>
        <b-card bg-variant="dark" text-variant="white" :title="data.contact.title">
            <div v-html="data.contact.body" />
            <div v-if="data.contact.linkedIn.display" class="mt-4">
                <div class="LI-profile-badge" data-version="v1" data-size="large" data-locale="en_US" data-type="horizontal" data-theme="dark" :data-vanity="data.contact.linkedIn.id" />
            </div>
        </b-card>
    </b-container>
</template>
<script>
    export default {
    }
</script>
<style scoped>
</style>
