<template>
    <b-container class="obs">
        <b-card :title="data.intro.about.title" class="intro-card mb-2 introcard">
            <div v-html="data.intro.about.body" />
        </b-card>
        <b-row
            v-for="(project, index) in data.projects"
            :key="index"
            :class="['mt-4', 'project-card', 'a2nimate__animated', {'a2nimate__fadeIn': index % 2 === 1}, {'a2nimate__fadeIn': index % 2 === 0}, 'an2imate__faster']"
        >
            <b-col cols="4" class="thumbnail">
                <div v-if="project.thumbnail.type === 'image'">
                    <img :src="project.thumbnail.url" class="img-fluid">
                </div>
                <div v-else :class="['animate-video', {'activeVideo': index === activeVideo}]">
                    <video preload="none" playsinline loop muted :controls="index === activeVideo">
                        <source :src="project.thumbnail.url" :type="project.thumbnail.type">
                    </video>
                    <div v-if="project.thumbnail.maximizable" class="video-btn">
                        <font-awesome-icon v-show="activeVideo === -1" icon="expand" @click="showVideo2(index)" />
                    </div>
                    <div v-show="activeVideo === index" class="video-close">
                        <font-awesome-icon icon="times" @click="hideVideo" />
                    </div>
                </div>
            </b-col>
            <b-col cols="8">
                <div class="d-inline main-title">
                    {{ project.content.title }}
                </div>
                <div class="d-inline float-right">
                    <b-badge
                        v-for="(tag, tagIndex) in project.content.tags"
                        :key="tagIndex"
                        variant="primary"
                        class="tag"
                    >
                        {{ tag }}
                    </b-badge>
                </div>
                <div>
                    <small>{{ project.content.secondaryTitle }}</small>
                </div>
                <div class="mt-1">
                    <a
                        v-for="(button, btnIndex) in project.content.buttons"
                        :key="btnIndex"
                        :href="button.url"
                        target="_blank"
                    >
                        <b-button size="sm" variant="info" class="card-btn">
                            {{ button.text }}
                        </b-button>
                    </a>
                </div>
                <div class="mt-2" v-html="project.content.body" />
            </b-col>
        </b-row>
        <div v-show="showVideoModal" class="video-container">
            <div class="video-content">
                <video style="width: 100%; height: 100%">
                    <source :src="videoUrl" :type="videoType">
                </video>
            </div>
        </div>
    </b-container>
</template>
<script>
    export default {
        data() {
            return {
                videoUrl: '',
                showVideoModal: false,
                videoType: '',
                activeVideo: -1
            }
        },
        mounted() {
            document.body.onscroll = this.onScroll
            this.onScroll()
        },
        methods: {
            showVideo(url, videoType) {
                this.videoUrl = url
                this.showVideoModal = true
                this.videoType = videoType
            },
            showVideo2(index) {
                this.activeVideo = index
            },
            hideVideo() {
                this.activeVideo = -1
            },
            onScroll() {
                const elementInViewport = (el) => {
                    let top = el.offsetTop
                    let left = el.offsetLeft
                    const width = el.offsetWidth
                    const height = el.offsetHeight

                    while (el.offsetParent) {
                        el = el.offsetParent
                        top += el.offsetTop
                        left += el.offsetLeft
                    }

                    return (
                        top >= window.pageYOffset &&
                        left >= window.pageXOffset &&
                        (top + height) <= (window.pageYOffset + window.innerHeight) &&
                        (left + width) <= (window.pageXOffset + window.innerWidth)
                    )
                }
                document.querySelectorAll('video').forEach(video => {
                    if (!video.getAttribute('autoplay') && elementInViewport(video)) {
                        video.setAttribute('autoplay', 'true')
                        video.setAttribute('preload', 'auto')
                    }
                })
            }
        }
    }
</script>
<style scoped>
.intro-card {
    padding: 15px 0px;
    box-shadow: rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 3px 1px -2px, rgb(0 0 0 / 20%) 0px 1px 5px 0px;
}
.project-card {
    padding: 15px 0px;
    box-shadow: rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 3px 1px -2px, rgb(0 0 0 / 20%) 0px 1px 5px 0px;
    /* box-shadow: 1px 5px 13px 0px rgb(0 0 0 / 55%); */
}
.introcard {
    box-shadow: rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 3px 1px -2px, rgb(0 0 0 / 20%) 0px 1px 5px 0px;
}
.tag {
    font-size: .9rem;
    font-weight: 400!important;
    padding: 5px!important;
    margin-right: 8px;
    border-radius: 0%;
}
.main-title {
    font-size: 1.4rem;
}
button {
    margin-right: 4px;
}
.video-container {
    background: rgba(0, 0, 0, .7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.video-btn {
    display: none;
    position: absolute;
    right: 45%;
    top: 42%;
    font-size: 30px;
    filter: drop-shadow(2px 3px 2px #000);
    cursor: pointer;
}
.video-close {
    position: absolute;
    right: 10px;
    top: -5px;
    font-size: 30px;
    filter: drop-shadow(2px 3px 2px #000);
    cursor: pointer;
}
.thumbnail:hover .video-btn {
    display: inline-block;
}
.animate-video {
    transform: none;
    position: none;
    text-align: center;
    transsition: transform .4s, position 2s ease 3s;
}
.animate-video video {
    width: 100%;
    height: 100%;
}
.activeVideo video {
    width: auto;
    height: auto;
}
.activeVideo {
    /* transform: scale3d(1.1, 1, 1.5); */
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.7);
    sanimation-name: size;
    animation-duration: .4s;
}
@keyframes size {
  from   {width: 50%; height: 50%;left: 20%; top: 30%;}
  to  {width: 100%; height: 100%; lefT: 0%; top: 0%;}
}
@media screen and (max-width: 700px) {
    video, .animate-video, .thumbnail  {
        display: none;
    }
}

@media screen and (max-width: 1160px) {
    .video-btn  {
        top: 30%;
    }
}

@media screen and (max-width: 991px) {
    .video-btn  {
        top: 15%;
    }
}
@media screen and (max-width: 767px) {
    .video-btn  {
        top: 10%;
        right: 40%;
    }
}
</style>
